import { template as template_4bbafde93cd14f269cb166c76d73ad90 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4bbafde93cd14f269cb166c76d73ad90(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
