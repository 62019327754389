import { template as template_7b2c2e2e4bc542f2be18f7e190b2c6f2 } from "@ember/template-compiler";
const FKText = template_7b2c2e2e4bc542f2be18f7e190b2c6f2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
